#toast {
	position: fixed;
	bottom: 80px;
	right: 32px;
	z-index: 99999999;
}

.toast {
	display: flex;
	align-items: center;
	border-radius: 2px;
	border-left: 4px solid;
	padding: 20px 0;
	box-shadow: 0 5px 8px rgba(0, 0, 0, 0.08);
	width: 500px;
	margin: auto;
	transition: all linear 0.3s;
	background: #f2f2f2;
}

@keyframes slideInLeft {
	from {
		opacity: 0;
		transform: translateX(calc(0% + 32px));
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeOut {
	to {
		opacity: 0;
	}
}

.toast + .toast {
	margin-top: 20px;
}

.toast__title {
	font-size: 16px;
	font-weight: 600;
	color: #333;
}

.toast__msg {
	font-size: 14px;
	font-weight: 500;
	color: #888;
	margin-top: 5px;
}

.toast__icon {
	padding: 10px;
}

.toast__close {
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 40px;
}

.toast__body {
	width: 100%;
}

.icon__toast--close::after {
	content: '';
	border: 1px solid #3a3b3c;
	height: 10px;
	display: block;
	position: absolute;
	transform: rotate(45deg);
}

.icon__toast--close::before {
	content: '';
	border: 1px solid #3a3b3c;
	height: 10px;
	display: block;
	position: absolute;
	transform: rotate(-45deg);
}

.toast--success {
	border-color: $color-primary;
	.toast__body {
		.toast__title {
			color: $color-primary;
		}
		.toast__msg {
			color: #3a3b3c;
		}
	}
	.toast__icon {
		background-image: url('../../../../public/images/toast/success.svg');
		width: 50px;
		background-repeat: no-repeat;
		background-position: center center;
		height: 50px;
	}
}

.toast--info {
	border-color: $color-primary;
	.toast__body {
		.toast__title {
			color: $color-primary;
		}
		.toast__msg {
			color: #3a3b3c;
		}
	}
}

.toast--warning {
	border-color: #ed1e24;
	.toast__body {
		.toast__title {
			color: #ed1e24;
		}
		.toast__msg {
			color: #3a3b3c;
		}
	}
	.toast__icon {
		background-image: url('../../../../public/images/toast/warning.svg');
		width: 50px;
		background-repeat: no-repeat;
		background-position: center center;
		height: 50px;
	}
}

.toast--error {
	border-color: #ed1e24;
	.toast__body {
		.toast__title {
			color: #ed1e24;
		}
		.toast__msg {
			color: #3a3b3c;
		}
	}
}

.toast__icon {
	.icon__toast--warning::after {
		content: '';
		background-image: url('../../../../public/images/toast_warning.svg');
		width: 40px;
		height: 40px;
		background-position: center;
		background-repeat: no-repeat;
		display: block;
	}

	.icon__toast--error::after {
		content: '';
		background-image: url('../../../../public/images/toast_warning.svg');
		width: 40px;
		height: 40px;
		background-position: center;
		background-repeat: no-repeat;
		display: block;
	}

	.icon__toast--success::after {
		content: '';
		background-image: url('../../../../public/images/toast_success.svg');
		width: 40px;
		height: 40px;
		background-position: center;
		background-repeat: no-repeat;
		display: block;
	}
}

/* ---------------------------------- */

.group__btn {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.btn {
	width: 200px;
	padding: 10px;
	border-radius: 5px;
	margin-top: 10px;
	text-align: center;
	color: #fff;
	font-weight: bold;
}

.btn--success {
	background-color: #47d864;
}

.btn--danger {
	background-color: #ff623d;
}
