.paymment__container {
	div {
		background-size: 100%;
		border-radius: 10px;
	}

	> div:first-child {
		background-image: url('../../../../public/images/payment/total_jobs.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}

	> div:nth-child(2) {
		background-image: url('../../../../public/images/payment/complete.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}

	> div:nth-child(3) {
		background-image: url('../../../../public/images/payment/pending.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}

	> div:nth-child(4) {
		background-image: url('../../../../public/images/payment/total_cost.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}

	> div:nth-child(5) {
		background-image: url('../../../../public/images/payment/pay.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}
}

.header_total_controller {
	div {
		background-size: 100%;
		border-radius: 10px;
	}

	> div:first-child {
		background-image: url('../../../../public/images/payment/total_jobs.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}

	> div:nth-child(2) {
		background-image: url('../../../../public/images/payment/complete.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}

	> div:nth-child(3) {
		background-image: url('../../../../public/images/payment/pending.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}

	> div:nth-child(4) {
		background-image: url('../../../../public/images/payment/total_cost.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}

	> div:nth-child(5) {
		background-image: url('../../../../public/images/payment/pay.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}
}

.total__block > div {
	padding: 10px;
	margin: 0 5px;
	border-radius: 10px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.job__header {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #333333;
	background: #e8f2fe;
	padding: 10px;
}
