.page__filter {
	display: flex;
	cursor: pointer;
}

.rs-picker-toggle {
	width: 270px;
	height: 41px;
	margin-top: 8px;
}
.rs-btn-default {
	border: 1px solid lightgrey !important;
	border-radius: 5px !important;
}

.fiter__status {
	margin-left: 10px !important;
}

.MuiFormLabel-root,
.status__content,
#filter__label {
	font-size: 12px !important;
}

#filter__label,
#select-label {
	background-color: $color-white !important;
	padding-right: 5px;
}

.rs-picker-toggle-value {
	color: #000 !important ;
	margin-top: 3px !important;
	font-family: 'Be VietNam' !important;
	font-size: 16px !important;
}

.rs-stack-item {
	span {
		margin-top: 2.1px !important;
	}
	svg {
		margin-top: 2px !important;
	}
}

.rs-picker-daterange-header {
	display: none !important;
}

.rs-picker-toolbar {
	justify-content: flex-end !important;
	> .rs-stack-item:first-child {
		display: none !important;
	}
}

.rs-picker-toolbar-right {
	button {
		width: 100px;
	}
}

.filter__search {
	position: relative;
	margin-bottom: 0px;
	width: 300px;
	.MuiFormControl-root {
		margin-left: 0px !important;
		margin-right: 0px !important;
		width: 100% !important;
	}
}

.filter__btn--search {
	position: absolute;
	left: 20px;
	top: 50%;
	transform: translateY(-50%);
}

.filter__input--search {
	padding: 9px;
	padding-left: 40px;
	.MuiInputBase-root .MuiInputBase-input {
		padding-left: 40px !important;
	}
}

.p-dropdown-label {
	padding: 10px;
}

.filter__dropdown {
	.p-dropdown-label {
		padding: 7px;
		width: 150px;
	}
}

.status__image {
	margin-right: 5px;
}

.status__option:nth-child(2) {
	.status__content {
		color: $color-success;
	}
}

.status__option:nth-child(3) {
	.status__content {
		color: $color-warning;
	}
}

.status__option:nth-child(4) {
	.status__content {
		color: $color-danger;
	}
}

.status__option.status__dots {
	margin-right: 10px;
}

#status__select {
	display: flex !important;
	align-items: center;
}

.filter__button {
	display: flex;
	justify-content: center;
	align-items: center;
	button {
		height: 40px;
	}
}

.p-calendar {
	width: 100%;
	border: none;
}

.image__calendar {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	background-color: #fff;
}

#range input {
	padding: 9px 15px;
	border-radius: 5px;
	width: 270px;
	border: 1px solid #ced1d7;
}

.filter__user .p-dropdown-label {
	padding: 8px;
	border-radius: 4px;
}

.filter__calendar input {
	border-radius: 4px;
	padding: 9px 15px;
	width: 270px !important;
	border: 1px solid #ced1d7;
}

.filter__reset {
	height: 35px;
}

.css-e53awj-MuiStack-root {
	justify-content: end;
}

.p-paginator .p-dropdown {
	height: 37px;
}

.filter__mobile {
	display: none;
	padding: 6px;
	border: 1px solid $color-border-disable;
	border-radius: 5px;
}
