.total__table {
	width: 100%;
	display: flex;
	justify-content: space-around;
}

.total__block {
	height: 180px;
}

.total__block:first-child {
	margin-left: 0;
}

.total__block:last-child {
	margin-right: 0;
}

.total__title {
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 24px;
	color: black;
	max-width: 120px;
	height: 50px;
	padding-top: 10px;
}

.total__count {
	margin-top: 5px;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 40px;
	color: black;

	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: nowrap;
	max-width: 100%;
}

.table_row {
	display: flex;
	width: 100%;
	border-bottom: 1px solid #e5e5e5;
}

.table_row.header {
	background-color: $color-table-header;
	padding: 10px 0;
	height: 53px;
}

.table__header-col.center {
	justify-content: center;
}

.table__header-col {
	line-height: 100%;
	display: flex;
	padding: 0 5px;
	box-sizing: border-box;
	font-size: 14px;

	span {
		color: $color-black;
		font-weight: 600;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		line-height: 16px;
		font-size: 11px;
	}
}

.table__container {
	background-color: $color-white;
	width: 100%;
	border-radius: 10px;
	margin-top: 30px;
	margin-bottom: 50px;
	padding: 20px;
}

.table__limit {
	display: flex;
	justify-content: flex-end;

	> span {
		font-size: 14px;
		line-height: 16px;
		color: #223354;
		display: flex;
		align-items: center;
		height: 80px;
		padding-bottom: 5px;
	}
}

.table__limit {
	.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
		border: none !important;
	}

	.MuiFormControl-root {
		justify-content: center !important;
	}
}

.p-datepicker-decade {
	span {
		font-size: 12px;
	}
}

.table__sort {
	position: absolute;
	right: -7px;
	top: 7px;
	cursor: pointer;
}

.sort__up {
	position: absolute;
	top: -8px;
	left: -4px;
	width: 10px;
	margin-left: 4px;
}

.sort__down {
	position: absolute;
	bottom: -8px;
	right: -10px;
	width: 10px;
	margin-left: 4px;
}

.table__body-col {
	height: 80px;
	display: flex;
	align-items: center;
	padding: 0 5px;
	box-sizing: border-box;
	background-color: $color-bgTable;
	font-size: 14px;
}

.table__body-name {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;

	.customer_name {
		text-shadow: 0.3px 0.3px 0.3px #000;
	}
}

.table__body-col.center {
	justify-content: center;
}

.table__header-name.haft-text {
	width: 60%;
}

.table__body-name.center {
	text-align: center;
}

.table__body-name.text-bold,
.text-bold {
	font-weight: 600;

	.customer_name {
		font-weight: 900;
		text-shadow: 1px 0 0 #3b3b3b;
	}
}

.table__body-name.text-blue {
	color: $color-primary;
}

.table__header-name {
	position: relative;
}

.mobile__infor {
	width: 100%;
	// color: white;
	border-radius: 5px;
	margin: 5px;

	&.btn_success {
		background-color: #ecfbe6;
	}

	&.btn_pending {
		background-color: #fff6e8;
	}

	&.btn_primary {
		background-color: $color-infor;
	}

	&.btn_stop {
		background-color: #ffe8ec;
	}
}

.p-label.btn_success,
.p-float-label.btn_success,
.table__body-name.btn_success {
	color: $color-black;
	background-color: #ecfbe6;
	border-radius: 5px;
	padding: 2px 10px;
	width: 155px;
	height: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center !important;
	margin: auto;

	&::before {
		content: '';
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: $color-success;
		display: block;
		margin-right: 5px;
	}
}

.btn-user {
	width: unset !important;
}

.p-label.btn_pending,
.p-float-label.btn_pending,
.table__body-name.btn_pending {
	color: $color-black;
	background-color: #fff6e8;
	border-radius: 5px;
	padding: 2px 10px;
	width: 155px;
	height: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center !important;
	margin: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: nowrap;

	&::before {
		content: '';
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: $color-warning;
		display: block;
		margin-right: 5px;
	}
}

.p-label.btn_primary,
.p-float-label.btn_primary,
.table__body-name.btn_primary {
	color: $color-black;
	background-color: $color-infor;
	border-radius: 5px;
	padding: 2px 10px;
	width: 155px;
	height: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center !important;
	margin: auto;

	&::before {
		content: '';
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: $hover-button-primary;
		display: block;
		margin-right: 5px;
	}
}

.notfound {
	text-align: center;
	padding: 10px;
	font-size: 18px;
	color: #cbcbcb;
	text-transform: uppercase;
}

.no_warning {
	height: 24px !important;
}

.p-label.btn_stop,
.p-float-label.btn_stop,
.table__body-name.btn_stop {
	color: $color-black;
	background-color: #ffe8ec;
	border-radius: 5px;
	padding: 2px 10px;
	width: 155px;
	height: 40px;
	display: block;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center !important;
	margin: auto;

	&::before {
		content: '';
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: $color-danger;
		display: block;
		margin-right: 5px;
	}
}

.paginate {
	display: flex;
	justify-content: center;
	padding: 30px 0;

	li {
		width: 30px;
		height: 30px;
		background-color: $color-white;
		border-radius: 5px;
		margin: 0 5px;
		justify-content: center;
		align-items: center;
		display: flex;

		a {
			color: $color-bloom;
			font-weight: bold;
		}
	}

	.selected {
		background-color: $color-primary;

		a {
			color: $color-white;
		}
	}
}

.p-column-title {
	color: $color-black;
	font-weight: 600;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	line-height: 16px;
	font-size: 11px;
}

.p-datatable .p-datatable-tbody > tr {
	height: 65px;
	position: relative !important;
}

.p-datatable .p-datatable-tbody > tr:hover {
	background-color: #dfdfdf87 !important;
	-webkit-transition: background-color 500ms ease;
	-ms-transition: background-color 500ms ease;
	transition: background-color 500ms ease;
}

.p-datatable .p-datatable-tbody {
	.approval-job {
		background-color: #dfdfdf87 !important;
		border-left: 5px solid $color-primary;
	}
}

.p-datatable-thead tr th {
	background-color: $color-secondary !important;
	color: $color-white !important;
	font-weight: 400 !important;
	align-items: center;
	text-transform: uppercase;
	height: 45px;
	padding: 1rem 0rem !important;
	// margin: 1rem 1rem;
}

.p-datatable .p-datatable-tbody > tr > td {
	text-align: center !important;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: nowrap;
	max-width: 160px;
	cursor: pointer;
}

.p-button:enabled:hover {
	background: inherit;
	color: inherit;
	border-color: inherit;
}

.p-dialog-mask {
	z-index: 999999999999999 !important;
}

.btn__submit:enabled:hover {
	background: $color-primary !important;
	color: white;
	border-color: inherit;
}

.p-datatable-emptymessage td {
	max-width: 100% !important;
}

.p-datatable .p-column-header-content {
	justify-content: center !important;
	border-right: 1px solid #dfdada;
	height: 100%;
}

tr th:last-child .p-column-header-content {
	border: none;
}

.p-datatable-thead {
	height: 50px;
}

.columnheader {
	display: flex;
	align-items: center;
	height: 65px;
}

.table__total--workflow--management {
	margin: 20px 0;
	margin-bottom: 105px;
}

.chart__title {
	font-weight: bold;
}

.table__analysis {
	margin-top: 20px;
}

.chart_bar {
	width: 100%;
}

@media (max-width: 767px) {
	.table__analysis > div {
		height: 100% !important;
	}
}

.infor_avatar {
	background: white;
	border: 6px solid white;
	position: relative;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
	-o-object-fit: cover;
	object-fit: cover;
	will-change: transform;
	-webkit-transition: -webkit-transform 1.5s ease-out;
	transition: -webkit-transform 1.5s ease-out;
	-moz-transition: transform 1.5s ease-out, -moz-transform 1.5s ease-out;
	transition: transform 1.5s ease-out;
	transition: transform 1.5s ease-out, -webkit-transform 1.5s ease-out, -moz-transform 1.5s ease-out;
	border-radius: 50%;
}

.avatar__block {
	display: flex;
	justify-content: center;
	top: 50px;
	left: 40px;
}

#avatar {
	display: none;
}

.label_avatar {
	bottom: 0;
	padding-left: 150px;
}

.label_avatar:before {
	content: '';
	background-image: url('../../../../public/images/personal/btn_camera.svg');
	display: block;
	width: 40px;
	height: 40px;
	cursor: pointer;
	background-repeat: no-repeat;
	position: absolute;
	right: -5px;
	bottom: -5px;
}

.p-dropdown {
	width: 100%;
}

.p-float-label .p-placeholder {
	opacity: 1;
}

.p-float-label::placeholder {
	display: block;
}

.cursor__normal {
	cursor: default;
}

.cursor__edit:hover {
	cursor: url('../../images/edit.svg'), auto;
}

.image__trash {
	cursor: pointer;
}

.p-confirm-popup {
	z-index: 99999 !important;
}

.create__job--title > .p-button {
	border: none;
	width: 0;
}

.p-confirm-popup-accept {
	background: $color-primary;
}

.p-confirm-popup-reject span {
	color: $color-black;
}

.p-button:enabled:active {
	background: inherit;
}

.p-confirm-popup-accept:hover {
	background: inherit !important;
	border: none;
	// font-size: 0.9rem !important;
}

.dialog__draft--title {
	color: $color-primary;
}

// .p-button:enabled:hover{
//     // background: $color-white;
//     // border: none;
// }

.p-button:focus {
	box-shadow: none;
}

.p-inputtext:not(:placeholder-shown) + label {
	opacity: 1;
	visibility: visible;
}

.open__modal {
	color: #1975ff !important;
	cursor: pointer;
	padding-top: 40px;
}

.open__modal.text-bold {
	font-weight: bold;
}

.btn__redirect {
	border: none;
	background-color: $color-primary;
	padding: 10 20px;
	width: 50px;
	border-radius: 0px 5px 5px 0px;
	height: 42px;
}

//  .pi-chevron-down:before{
//     content:"";
//     background-image: url("../../../../public/images/arrow_down.svg");
//     background-repeat: no-repeat;
//     background-position:center;
//     width: 15px;
//     height: 15px;
//     display:block;
//  }

.p-datatable-emptymessage td {
	justify-content: center;
	font-size: 20px;
	color: $color-light-bloom;
}

.ava_res {
	display: none;
}

.p-datatable .p-datatable-tbody > tr > .table__total {
	cursor: url('../../../../public/images/edit.svg'), auto !important;
	background-color: $color-input !important;
	padding: 15px 10px;
}

.p-datatable .p-datatable-tbody > tr > .table__total:first-child {
	cursor: auto !important;
}

.edit_bank {
	padding: 2px 5px;
	width: 80%;
	height: 45px;
}

.text_table_bank {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	display: block;
}

.p-password {
	width: 100%;
}

.pi-eye-slash:before {
	content: '';
	background-image: url('../../../../public/images/eye.png');
	background-repeat: no-repeat;
	background-position: center;
	width: 20px;
	height: 20px;
	background-size: 20px;
	display: block;
}

.pi-eye:before {
	content: '';
	background-image: url('../../../../public/images/closed_eye.png');
	background-repeat: no-repeat;
	background-position: center;
	width: 20px;
	height: 20px;
	background-size: 20px;
	display: block;
}

.p-toast {
	z-index: 9999999999 !important;
}

.btn_filter {
	display: none;
}

.table__perpage {
	height: 65px;
	position: relative;
	display: flex;

	> span {
		position: absolute;
		right: 200px;
		top: 20px;
		font-size: 12px;
		color: $color-black;
	}
}

.pi {
	cursor: pointer;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
	border-radius: 5px;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover {
	border: 1px solid $color-border-input !important;
}

.select__perpage {
	width: inherit;
	position: absolute;
	top: -65px;
	right: 85px;
	background-color: #fbfafa;
	margin-top: 10px;
	height: 40px;
	scrollbar-width: none !important;

	.p-inputtext {
		color: $color-black;
	}
}

.select__perpage__text {
	font-size: 12px;
	color: $color-black;
	width: inherit;
	position: absolute;
	top: -55px;
	right: 10px;
	margin-top: 10px;
	height: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: nowrap;
	max-width: 70px;
}

.p-paginator {
	padding: 0px;
}

.p-paginator-current {
	display: none;
}

.p-dropdown-trigger-icon::before {
	background-image: url('../../../../public/images/arrow_down_black.svg');
	background-size: 10px;
	background-repeat: no-repeat;
	background-position: center;
	width: 20px;
	height: 20px;
	display: block;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	color: $color-primary;
	border-color: none;
}

.p-link:focus {
	outline: none;
	outline-offset: 0;
	box-shadow: none;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	border-radius: 5px;
	height: 40px;
	min-width: 40px;
	padding: 0px;
}

.color__name {
	color: $color-primary;
}

.color__price {
	color: $color-avatar;
}

.color__warning {
	color: $color-warning;
}

.color__green {
	color: $color-success;
}

.p-button {
	background-color: $color-primary;
	border: none;
}

.empty_data {
	text-align: center;
	width: 100%;
	padding: 10px;
	text-transform: uppercase;
	color: $color-border-input;
	font-weight: bold;
	font-size: 14px;
}

.pi-times-circle {
	position: relative;
}

.pi-times-circle:before {
	content: 'x' !important;
	color: $color-primary;
	font-size: 12px;
	position: absolute;
	bottom: 10px;
	background-color: $color-white;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 12px;
	height: 12px;
	opacity: 0.8;
	right: -15px;
}

.p-chips-token-label {
	color: $color-white;
}

.p-chips-token {
	background-color: $color-primary !important;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
	border-color: none !important;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
	outline: none !important;
	box-shadow: none !important;
}

#pr_id_7,
.p-dialog-header,
#pr_id_7_header {
	cursor: auto !important;
}

.p-dialog-header-icons:after {
	content: 'x';
	position: absolute;
	right: 30px;
	cursor: pointer;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
	z-index: 99;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
	outline: none;
	outline-offset: 0;
	box-shadow: none;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
	color: transparent;
	border-color: transparent;
	background: transparent;
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
	outline: none;
	outline-offset: 0;
	box-shadow: none;
}

.p-listbox-filter-icon {
	padding-left: 10px;
}

.pi-search:before {
	content: '';
	background-image: url('../../../../public/images/search.svg');
	width: 20px;
	height: 20px;
	display: block;
	background-repeat: no-repeat;
	background-position: center;
}

.member__search .p-listbox-header {
	background-color: $color-white;

	.p-listbox-filter-container {
		background-color: #d0d5dd1c;
		border-radius: 10px;
		border: 1px solid #b2b2b2;
	}

	.p-listbox-filter-container input {
		width: 95%;
		background-color: #d0d5dd1c;
		border: none;
		border-radius: 10px;
	}
}

.member__search .p-listbox-list-wrapper {
	max-height: 150px;
	overflow-y: auto;
}

.members-item {
	position: relative;
}

.members-item.active::after {
	content: '';
	right: 0px;
	top: 12px;
	width: 10px;
	height: 2px;
	border-width: 0 3px 2px 0;
	transform: rotate(40deg);
	background-color: $color-primary;
	display: block;
	position: absolute;
}

.members-item.active::before {
	content: '';
	right: -10px;
	top: 10px;
	width: 15px;
	height: 2px;
	border-width: 0 3px 2px 0;
	transform: rotate(-45deg);
	background-color: $color-primary;
	display: block;
	position: absolute;
}

.p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
	background: inherit !important;
	border-color: inherit !important;
	color: inherit !important;
}

.table__unit {
	font-size: 9px !important;
	display: block !important;
	text-align: center;
}

.chart__color {
	position: relative;
}

.chart__color.red:after {
	content: ' ';
	background-color: #ff6384;
	position: absolute;
	bottom: 35px;
	display: block;
	width: 100px;
	height: 20px;
}

.chart__color.blue:after {
	content: ' ';
	background-color: $color-primary;
	position: absolute;
	bottom: 35px;
	display: block;
	width: 100px;
	height: 20px;
}

.chart__color.yellow:after {
	content: ' ';
	background-color: #ffce56;
	position: absolute;
	bottom: 35px;
	display: block;
	width: 100px;
	height: 20px;
}

.footer__msg {
	height: 20px;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
	color: $color-black;
}

.table__add:enabled:hover {
	background: $hover-button-primary;
	color: #ffffff;
	border-color: $color-primary;
}

.p-datatable-emptymessage td {
	display: flex !important;
	justify-content: center !important;
	border-bottom: none !important;
	width: 100%;
	position: absolute;
}

.table__icon--add {
	font-size: 30px;
	display: block;
	padding-right: 5px;
}

.table__add {
	padding: 5px 20px;
}

.page__content {
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 131%;
	padding-top: 10px;
}

.p-calendar .p-inputtext {
	width: 100%;
}

.group_name_job {
	font-weight: 400 !important;
}

.group__customer {
	color: $color-name-notification;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: nowrap;
	max-width: 100%;
}
