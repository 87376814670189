.infor__title {
	font-size: 16px;
	color: $color-black;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
}

.infor__content {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #979ba0;
}

.p-button .p-button-label {
	margin-bottom: 0 !important;
}

.infor__block .field span:first-child {
	font-size: 13px;
	margin-bottom: 10px;
	display: block;
}

.infor__submit {
	width: 120px;
	background-color: $color-primary !important;
	&:hover {
		background-color: $hover-button-primary !important;
	}
	span {
		color: $color-white !important;
		font-size: 13px;
	}
}

.personal__container {
	background-image: url('../../../../public/images/personal/unsplash_jhnvYd8FFQc.svg');
	height: 100%;
	background-repeat: no-repeat;
	background-size: 100% 180px;
	width: 100%;
	background-color: white;
	border-radius: 50px 50px 0 0;
	margin-top: 20px;
	padding: 0 30px;
	> div:first-child {
		margin-top: 100px;
	}
}

.p-button-outlined {
	color: $color-primary !important;
	border: 1px solid $color-primary !important;
}

.card__bank {
	background-image: url('../../../../public/images/personal/payment.svg');
	max-width: 500px;
	background-size: 100%;
	background-repeat: no-repeat;
	height: 200px;
	background-position: center;
	top: -30px;
	right: 0;
	width: 100%;
	height: 500px;
	margin: auto;
}

.card__bank--infor {
	background-image: url('../../../../public/images/personal/payment.svg');
	max-width: 500px;
	background-size: 100%;
	background-repeat: no-repeat;
	height: 100%;
	background-position: center;
	top: -30px;
	left: 50%;
	width: 100%;
}

.card__name--bank {
	position: absolute;
	top: 40%;
	left: 20%;
	color: white;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 600;
}

.card__nameUser--bank {
	position: absolute;
	top: 50%;
	left: 20%;
	color: white;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: nowrap;
	max-width: 330px;
}

.card__number--bank {
	position: absolute;
	top: 54%;
	left: 20%;
	color: white;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 600;

	span {
		line-height: 20px;
	}

	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: nowrap;
	max-width: 330px;
}

// .personal__block {
// 	padding-top: 250px;
// }

.mask__image {
	position: relative;
	border-radius: 50%;
	display: block;
	width: 160px;
	height: 160px;
	font-family: inherit;
	line-height: normal;
	overflow: hidden;
}

.button__personal--cancel {
	width: 130px;
}

// .p-button:enabled:hover,
// .p-button:not(button):not(a):not(.p-disabled):hover {
// 	background: none;
// 	color: #ffffff;
// 	border-color: none;
// }

.personal__information {
	p {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: $color-gray-dark;
	}
}

.config__icon {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;

	&--icon-down {
		background-image: url('../../../../public/images/arrow_down_black.svg');
		background-size: 10px;
		background-repeat: no-repeat;
		background-position: center;
		width: 20px;
		height: 20px;
		display: block;
	}

	&--icon-up {
		background-image: url('../../../../public/images/arrow_up_black.svg');
		background-size: 10px;
		background-repeat: no-repeat;
		background-position: center;
		width: 20px;
		height: 20px;
		display: block;
	}
}
