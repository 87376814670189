.login__container {
	display: flex;

	.login__bg {
		width: 50%;
		height: 100vh;
		align-items: center;
		display: flex;
		&::before {
			content: '';
			position: absolute;
			background-image: url('../../../../public/images/logo/logo.svg');
			display: block;
			background-repeat: no-repeat;
			background-position: center center;
			width: 200px;
			height: 200px;
			background-size: 100%;
			left: 100px;
			top: 50%;
			z-index: 1;
			transform: translateY(-50%);
		}
		.login__bg--title {
			color: white;
			left: 310px;
			top: 50%;
			color: white;
			transform: translateY(-50%);
			font-family: 'Be VietNam';
			z-index: 1;
			p {
				font-size: 35px;
			}
			span {
				color: $color-primary;
				margin-left: 50px;
				display: block;
				font-size: 50px;
				max-width: 320px;
			}
		}
		img {
			display: flex;
			max-height: 100%;
			border-radius: 50px;
			width: 100%;
			padding: 30px;
			position: relative;
			height: 100%;
		}
	}

	.login__form {
		width: 50%;
		padding: 30px;
		max-width: 550px;
		margin: auto;
		.login__title {
			display: flex;

			p:first-child {
				font-size: 32px;
				line-height: 47px;
				color: $color-bloom;
				margin-right: 5px;
			}

			p {
				margin-top: unset;
				font-size: 32px;
				line-height: 47px;
				color: $color-primary;
			}
		}
	}

	.login__route {
		font-size: 50px;
		line-height: 94px;
		color: $color-bloom;
		margin-bottom: 40px;
		font-weight: bold;
	}

	form {
		.form__email {
			margin-bottom: 10px;
			p {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 23px;
				color: $color-bloom;
				margin-bottom: 15px;
			}

			input {
				border: none;
				font-size: 16px;
				width: 100%;

				&:focus-visible {
					outline: none;
					background: white;
				}
			}
		}

		.form__password {
			margin-bottom: 10px;
			p {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 23px;
				color: $color-bloom;
				margin-bottom: 15px;
			}

			.form__input {
				display: flex;

				input {
					border: none;
					font-size: 16px;
					width: 100%;

					&:focus-visible {
						outline: none;
					}
				}

				.show__password {
					cursor: pointer;
				}
			}
		}

		.form__save--information {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;
		}

		.form__btn-submit {
			button {
				background-color: $color-primary;
				color: $color-white;
				font-size: 16px;
				line-height: 100%;
				border: none;
				border-radius: 10px;
				width: 100%;
				padding: 15px 0;
				cursor: pointer;
				&:hover {
					background-color: $hover-button-primary;
				}
			}
		}
	}
}

.form__btn-forgotpwd {
	font-size: 14px;
	color: $color-primary;
	line-height: 20px;
	cursor: pointer;
	display: flex;
}

.form__input {
	width: 100%;
	border: 1px solid $color-primary;
	padding: 15px;
	box-sizing: border-box;
	border-radius: 10px;
}

.form__input__disabled {
	background-color: #ccc7c74f;
}

.save__me {
	display: flex;
	font-size: 14px;
	color: $color-secondary;
	line-height: 20px;

	input {
		width: 20px;
		margin-right: 20px;
	}
}

.form__error {
	color: $color-danger;
	font-size: 13px;
	padding-top: 10px;
	display: block;
	height: 22px;
}

.login__note {
	font-size: 16px !important;
	color: white !important;
	margin-top: 15px;
	line-height: 30px;
	font-family: 'Be VietNam' !important;
	margin-left: 0px !important;
}

.form__forgot-pwd {
	min-height: 550px;
}

.p-button.p-button-secondary:enabled:focus {
	box-shadow: none;
}
