.chat-container {
	display: flex;
	position: absolute;
	bottom: 20px;
	right: 45px;
	z-index: 9999;
	box-shadow: -2px 8px 8px rgb(0 0 0 / 30%);
	border-radius: 20px;
	height: 700px;
	background-color: white;
}

.group__chat--btn {
	background: #f8f9fa;
	border: 1px solid #d9d9d9;
	border-radius: 0 0 10px 10px;
	padding: 5px 10px;
	position: relative;
}

.list {
	position: relative;
	cursor: pointer;
	&::-webkit-scrollbar {
		width: 0px;
	}
}

.people-list {
	width: 300px;
	transition: 0.3s linear;
	background: white;
	border-radius: 0 0 0 20px;
	.search {
		padding: 20px;
	}

	.message__title {
		padding: 12px 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px 0 0 0;
		.notification-message__search {
			opacity: 0;
			width: 0;
			height: 0;
		}
	}

	.fa-search {
		position: relative;
		left: -25px;
	}

	ul {
		overflow-y: auto;
		max-height: 411px;
		li {
			display: flex;
			padding: 20px 10px 10px 20px;
			justify-content: center;
			position: relative;
			border-bottom: 1px solid $color-border-input;
		}
		li.active {
			background-color: $active-mess;
		}
		li:last-child {
			border: none;
		}
	}

	.about {
		.status {
			font-size: 9px;
			display: none;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: nowrap;
			max-width: 120px;
		}
		.name {
			display: none;
			font-size: 12px;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: nowrap;
			max-width: 120px;
		}
	}

	.about {
		padding-left: 5px;
		.name {
			display: block;
			transition: 0.3s linear;
		}
		.status {
			display: block;
			transition: 0.3s linear;
		}
	}
	.avatar_me {
		display: none;
	}
	.message__title {
		padding-bottom: 18px;
	}
	ul li {
		border-bottom: 1px solid $color-white;
		justify-content: inherit;
	}
	ul li:last-child {
		border-bottom: none;
	}

	.notification-message__search {
		opacity: 1;
		transition: 0.3s linear;
		width: inherit;
		height: inherit;
	}
	.has_seen {
		top: 30px;
		right: 10px;
	}
}

.chat-header.active::after {
	content: '';
	position: absolute;
	left: -10px;
	top: 0;
	background-color: white;
	width: 10px;
	height: 100%;
}

.chat {
	width: 800px;
	float: left;
	background: $color-white;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	color: #434651;
	border-left: 1px solid #d9d9d9;
	.chat-header {
		padding: 5px 10px 5px 10px;
		border-bottom: 1px solid #d9d9d9;
		display: flex;
		position: relative;
		height: 64px;
		img {
			float: left;
		}

		.chat-about {
			float: left;
			padding-left: 10px;
			margin-top: 10px;
		}

		.chat-with {
			font-weight: bold;
			font-size: 13px;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: nowrap;
			max-width: 160px;
			overflow: hidden;
		}

		.chat-num-messages {
			font-size: 13px;
		}

		.fa-star {
			float: right;
			color: #d8dadf;
			font-size: 20px;
			margin-top: 12px;
		}
	}

	.chat-history {
		padding: 10px 10px 20px;
		border-bottom: 2px solid white;
		overflow-y: scroll;
		height: 525px;

		.message-data {
			margin-bottom: 15px;
			display: flex;
			justify-content: center;
		}

		.message-data-time {
			width: max-content;
			text-align: center;
			font-size: 11px;
			color: white;
			position: relative;
			background-color: #8fa0af;
			padding: 5px;
			border-radius: 20px;
		}

		.my-message {
			color: $color-white !important;
		}

		.my-message p {
			background: #e8f2fe;
			color: $color-black !important;
			padding: 10px;
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			border-radius: 20px 20px 0px 20px;
		}

		.other-message p {
			background-color: #eef1f4;
			padding: 10px;
			border-radius: 20px 20px 20px 0px;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			color: $color-black !important;
		}

		.message {
			color: $color-black;
			padding: 5px 0px;
			line-height: 26px;
			font-size: 13px;
			border-radius: 7px;
			margin-bottom: 10px;
			max-width: 75%;
			position: relative;
			overflow-wrap: break-word;
			display: flex;
			justify-content: center;
			flex-direction: column;
			&:after {
				bottom: 100%;
				left: 7%;
				border: solid transparent;
				content: ' ';
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-width: 10px;
				margin-left: -10px;
			}
			p {
				margin-bottom: 8px;
			}
		}

		.other-message {
			&:after {
				left: 93%;
			}
		}
	}

	.chat-history::-webkit-scrollbar {
		display: none;
	}

	.chat-message {
		padding: 10px;
		textarea {
			border: 1px solid #d9d9d9;
			border-radius: 10px 10px 0 0;
			width: 100%;
			padding: 10px 20px;
			font: 14px/22px 'Lato', Arial, sans-serif;
			resize: none;
		}

		.fa-file-o,
		.fa-file-image-o {
			font-size: 16px;
			color: gray;
			cursor: pointer;
		}

		button {
			float: right;

			border: none;
			cursor: pointer;
			background: #f2f5f8;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: #9e9e9e;
			border: 1px solid #d9d9d9;
			border-radius: 10px;
			width: 65px;
		}
	}
}

.online,
.offline,
.me {
	margin-right: 3px;
	font-size: 10px;
}

.online {
	color: $color-isOnline;
}

.offline {
	color: $color-danger;
}

.me {
	color: $color-primary;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.float-right {
	float: right;
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: ' ';
	clear: both;
	height: 0;
}

.message-data-alert {
	text-align: center;
	color: $color-primary;
	font-size: 13px;
	margin-bottom: 20px;
}

#people-list.active {
	.about {
		padding-left: 5px;
		.name {
			display: block;
			transition: 0.3s linear;
		}
		.status {
			display: block;
			transition: 0.3s linear;
		}
	}
	.avatar_me {
		display: none;
	}
	.message__title {
		padding-bottom: 18px;
	}
	ul li {
		border-bottom: 1px solid $color-white;
		justify-content: inherit;
	}
	ul li:last-child {
		border-bottom: none;
	}

	.notification-message__search {
		opacity: 1;
		transition: 0.3s linear;
		width: inherit;
		height: inherit;
	}
	.has_seen {
		top: 30px;
		right: 10px;
	}
}

.chat_avatar {
	width: 55px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chat_img {
	position: relative;
	height: 45px;
	border-radius: 50%;
}

.chat_img:after {
	content: attr(role);
	width: 45px;
	height: 45px;
	border-radius: 50%;
	display: flex;
	background: linear-gradient(to top left, #33ccff 0%, #ff99cc 100%);
	color: $color-white;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	font-weight: bold;
}

.chat_img[data-size='small']:after {
	content: attr(role);
	width: 30px !important;
	height: 30px !important;
	font-size: 20px !important;
}

.chat_img[data='ONLINE']:before {
	content: '';
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: $color-isOnline;
	display: block;
	margin-left: 5px;
	position: absolute;
	right: 1px;
	bottom: 0px;
}

.chat_img[data='OFFLINE']:before {
	content: '';
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #495057;
	display: block;
	margin-left: 5px;
	position: absolute;
	right: 1px;
	bottom: 0px;
}

.chat_img[data='LEAVING']:before {
	content: '';
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: $color-warning;
	display: block;
	margin-left: 5px;
	position: absolute;
	right: 1px;
	bottom: 0px;
}

.chat__file label:before {
	content: '';
	background-image: url('../../../../public/images/gim.svg');
	width: 27px;
	height: 30px;
	display: block;
	background-size: 10px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	background-size: 15px;
}

.chat__file {
	display: flex;
	align-items: flex-end;
	padding-bottom: 5px;
	padding-right: 10px;
}

.my-mess {
	margin-top: 5px;
	margin-left: 10px;
}

.your-mess {
	margin-top: 5px;
	margin-right: 10px;
}

.btn__mes:before {
	content: '';
	background-image: url('../../../../public/images/btn_chat.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 40px;
	position: absolute;
	bottom: -1px;
	right: -1px;
	width: 55px;
	height: 55px;
	border-radius: 50%;
}

.btn__messager {
	position: relative;
	position: absolute;
	bottom: 15px;
	right: 15px;
	width: 75px;
	height: 75px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 3px 4px 4px rgb(0 0 0 / 17%);
	cursor: pointer;
	transition: 0.5s linear;
	border: 10px solid #dbdde8;
	background-color: #51c332;
}

.btn__messager:hover {
	background-color: #219be4;
	transition: 0.5s linear;
	cursor: pointer;
}

.chat__close:before {
	content: '';
	position: absolute;
	top: -6px;
	right: -10px;
	width: 20px;
	height: 20px;
	background-color: $color-light-bloom;
	border-radius: 50%;
	opacity: 1;
	cursor: pointer;
}

.chat__close:after {
	content: 'x';
	position: absolute;
	top: -10px;
	right: -5px;
	color: $color-white;
	opacity: 1;
	transition: 0.3s linear;
	cursor: pointer;
	display: block;
}

.has_seen {
	position: absolute;
	right: 22px;
	font-size: 11px;
	color: #ffffff;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #ff0d39;
	text-align: center;
	margin-top: -10px;
}

.messsager__container {
	position: fixed;
	bottom: 0;
	width: 100px;
	right: 0;
}

.your__id-system {
	position: absolute;
	font-size: 10px;
	color: gray;
	right: 5px;
	top: 50px;
}

.your__id-system:before {
	content: 'Id của bạn: ';
}

.title_members {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 32px;
	color: $color-gray-dark;
	text-align: center;
	display: flex;
	align-items: center;
	img {
		padding-right: 5px;
	}
}

.logo_chat {
	width: 300px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.3;
}

.chat__createGroup:after {
	content: '+';
	position: absolute;
	left: -40px;
	width: 40px;
	height: 40px;
	background: $color-primary;
	font-size: 25px;
	border-radius: 5px 0 0 5px;
	color: $color-white;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: -3px 4px 4px rgb(0 0 0 / 17%);
	cursor: pointer;
}

.p-dialog .p-dialog-header {
	padding: 10px 20px;
}

.p-chips .p-chips-multiple-container .p-chips-token {
	margin: 5px;
}

.full_chat {
	height: 404px !important;
}

.copy__pwd {
	right: 10px;
	padding-top: 10px;
}

.copy__name {
	right: 10px;
	padding-top: 10px;
}

.preview__imgs {
	display: flex;
	left: 35px;
	position: absolute;
}

.preview__imgs div {
	width: 40px;
	height: 40px;
	margin: 0 2px;
	position: relative;
	img {
		width: 100%;
		height: 100%;
		border-radius: 5px;
	}
}

.div_preview:after {
	content: 'x';
	position: absolute;
	width: 10px;
	height: 10px;
	display: block;
	background: gray;
	opacity: 0.7;
	top: -5px;
	right: -10px;
	display: flex;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 8px;
}

.box__chat {
	background: white;
	border-radius: 10px;
	height: 44px;
}

.chat__image {
	cursor: pointer;
	img {
		width: 100%;
		border-radius: 10px;
	}
}

.p-image-mask {
	z-index: 99999999 !important;
}

.p-image-action:last-child:after {
	content: 'x';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.p-image-action.p-link:last-child {
	position: relative;
	border: 1px solid $color-white;
	border-radius: 50%;
}

.msg__title {
	border-bottom: 1px solid #d9d9d9 !important;
	position: relative;
	height: 64px;
	padding: 15px;
}

.notify_count {
	position: relative;
}

.notify_count::after {
	content: attr(count);
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	font-size: 11px;
	background-color: red;
	color: #fff;
	bottom: 12px;
	margin-right: 5px;
}
