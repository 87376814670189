$color-primary: #1890ff;
$color-nav-child-action: #ebf9ff;
$color-secondary: #fafafa;
$color-white: #ffffff;
$color-success: #3bb900;
$color-success-bg: #ecfbe6;
$color-warning: #f99500;
$color-danger: #ff0d39;
$color-text: #223354;
$color-bgTable: #789dd54a;
$color-msg-forgotPwd: #f8f8f8;
$color-msg-forgotPwd-error: #ffe8ec;
$color-avatar: #febd06;
$color-isOnline: #44d600;
$color-gray-dark: #333333;
$color-bloom: #000c57;
$color-light-bloom: #6e759f;
$color-light-blue: #f6f8fb;
$color-bg-table: #fbfafa;
$color-table-header: #34444c;
$color-border-input: #d0d5dd;
$color-black: #000000;
$color-input: #f5f5f6;
$color-name-notification: #6c6c6c;
$active-mess: #f2f5f8;
$color-border-disable: #d9d9d9;
$active-nav: #e6f7ff;
$hover-button-primary: #0a7ae2;
$color-infor: #c3e2ff;
