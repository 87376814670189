#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #d4d4d4;
	opacity: 0.6;
	z-index: 999999999999999999;
	display: none;
}
.loader {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	animation: rotate 1s linear infinite;
	top: 50%;
	transform: translate(-50%, -50%);
}
.loader::before,
.loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	inset: 0px;
	border-radius: 50%;
	border: 5px solid #fff;
	animation: prixClipFix 2s linear infinite;
}
.loader::after {
	border-color: $color-primary;
	animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
	inset: 6px;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes prixClipFix {
	0% {
		clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
	}
	25% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
	}
	50% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
	}
	75% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
	}
	100% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
	}
}
