.header__button {
	margin-right: 10px !important;
}

.css-1dxb3vu,
.css-168noda {
	background-color: $color-white !important;
	box-shadow: none !important;
	border-bottom: 1px solid $color-border-input !important;
}

.disabled {
	background-color: $color-border-input;
}

.disable {
	background-color: $color-border-input;
	border-radius: 5px;
}

.text_disable {
	color: black;
	font-weight: 600;
	border-color: $color-primary;
	background-color: $color-infor;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
	padding: 6.5px 14px !important;
}

.css-1t62lt9 {
	margin-right: 10px;
}

.page__header {
	position: absolute;
	right: 25px;
	top: 85px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between !important;
	padding-left: 125px;
}

.header__container {
	height: 155px;
	position: relative;

	> .MuiPaper-root {
		> .MuiToolbar-root {
			padding: 0 20px !important;
			background-color: $color-primary !important;
		}
	}

	.css-1191obr-MuiPaper-root-MuiAppBar-root,
	.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
		background-color: $color-primary !important;
		box-shadow: none !important;
		border: 0px solid $color-border-input !important;
	}
}

.header__right {
	align-items: center;
}

.header__right,
.information__roles,
.header__right--profile {
	display: flex;
}

.header__block {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $color-primary;
}

.header__left {
	color: $color-white;
	font-size: 20px;
	line-height: 23px;
	cursor: pointer;
}

.information__name {
	color: $color-bloom;
	display: flex;
	align-items: center;

	p {
		font-weight: bold;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		white-space: nowrap;
		max-width: 120px;
		color: $color-white;
	}
}

.information__roles {
	color: $color-white;

	p {
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		white-space: nowrap;
		max-width: 135px;
	}
}

.header__right--notification-msg,
.header__right--notification {
	position: relative;
	cursor: pointer;
}

.css-1wlk0hk-MuiAvatar-root {
	width: 35px !important;
	height: 35px !important;
}

.header__right--notification-msg img,
.header__right--notification img {
	margin: 0 10px;
	border-radius: 50%;
	width: 20px;
}

.mess__msg img {
	width: 40px !important;
}

.header__right--profile {
	margin-left: 20px;
	width: 200px;
	cursor: pointer;
	align-items: center;
	gap: 10px;
	.css-e53awj-MuiStack-root {
		margin-right: 10px;
	}
}

.header__right--information {
	width: 100%;
}

.information__roles {
	justify-content: space-between;
	padding-right: 25px;
}

.count_notification {
	background: #ff0d39;
	width: 24px;
	height: 24px;
	position: absolute;
	display: flex;
	border-radius: 50%;
	text-align: center;
	margin: auto 0;
	font-weight: 600;
	top: -15px;
	right: -5px;
	font-size: 11px;
	justify-content: center;
	align-items: center;
}

.dots_online {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: $color-isOnline;
	display: block;
	margin-left: 5px;
}

.dots_busy {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: $color-warning;
	display: block;
	margin-left: 5px;
}

.dots_offline {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: rgb(121 121 121);
	display: block;
	margin-left: 5px;
}

.header__menu {
	position: absolute;
	right: 0;
	top: 65px;

	.p-slidemenu .p-slidemenu-wrapper .p-slidemenu-backward .pi-chevron-left::before {
		top: 20px;
		left: 25px;
	}
}

.p-slidemenu .p-slidemenu-backward {
	height: 40px;
}

.header__menu-icon {
	padding: 0 5px;
	height: 13px;
}

.header__menu-icon.flex_right {
	position: absolute;
	right: 0px;
}

.p-inputtext:enabled:focus {
	border-color: inherit;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
	background: $color-primary;
}

.header__title {
	position: absolute;
	top: 62%;
	left: 100px;
}

.page__title {
	color: $color-black;
	text-transform: uppercase;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
}
