.form__forgotpwd {
	display: flex;
	justify-content: flex-start;
	color: $color-primary;
	margin-bottom: 30px;
}

.form__message {
	margin-bottom: 20px;
	width: 100%;
	height: 60px;
	background-color: $color-msg-forgotPwd;
	border-radius: 8px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.form__message_error {
	background-color: $color-msg-forgotPwd-error;
	color: $color-danger;
}

.reset__pasword {
	display: flex;
	flex-wrap: nowrap;
	height: 200px;
	width: 100%;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-bottom: 50px;

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
	}

	&--btn button {
		background-color: #1890ff;
		color: #ffffff;
		font-size: 16px;
		line-height: 100%;
		border: none;
		border-radius: 10px;
		width: 100%;
		padding: 15px 0;
		cursor: pointer;
	}
}
