.information__job span {
	font-size: 12px;
	color: $color-black;
}

.information_job_title {
	font-weight: bold;
}

.flex-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;

	img {
		width: 20px;
	}
}

.information__job .grid {
	margin-right: -20px;
}

.information__job .grid .field {
	margin-bottom: 20px;
}

.link {
	color: $color-primary !important;
}

.information_job_title .p-float-label {
	padding-top: 10px;
}

.p-inputnumber {
	width: 100%;
}

.p-sidebar-content {
	padding: 0px !important;
	overflow-x: hidden;
}

.image_messager {
	width: 30px;
}

#editor {
	width: 100%;
}

.btn__close {
	border: 1px solid #ccc;
	margin-top: 10px;
	color: $color-black !important;
}

.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
	background: transparent;
	color: none;
	border-color: none;
}

.text_seen {
	cursor: url('../../images/edit.svg'), auto;
}

#total_cost,
#exchange_rate,
#editor_cost,
#rate_sale,
#kpi_sale {
	width: 100%;
}

.MuiMenu-list li {
	padding-left: 10px !important;
}

.p-datatable-emptymessage td {
	background-color: $color-bg-table !important;
	border: none !important;
	padding-bottom: 20px !important;
}

.copy__icon__payments {
	width: 7% !important;
}

.btn_ref {
	padding: 10px;
	background-color: $color-primary;
	display: block;
	text-decoration: none;
	border-radius: 5px;
	color: white;
	cursor: pointer;
}

.btn_ref.disable {
	background-color: white;
	color: $color-border-disable;
	border: 1px solid $color-border-disable;
	cursor: default;
}

.modal__name {
	cursor: pointer;

	&--title {
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		color: rgba(0, 0, 0, 0.85);
	}

	&--introduce {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: rgb(88, 88, 88);
	}

	&--create-btn {
		color: #1890ff;
	}

	&--group {
		width: 50%;
	}

	&--icon__delete {
		padding-bottom: 10px;
	}
}

.p-float-label input::placeholder,
.p-float-label .p-inputtext::placeholder {
	opacity: 1;
}

.pi-chevron-up:before {
	content: '' !important;
	background-image: url('../../../../public/images/arrow_up_black.svg');
	width: 15px;
	height: 15px;
	display: block;
	background-position: center center;
	background-repeat: no-repeat;
}

.pi-chevron-down:before {
	content: '' !important;
	background-image: url('../../../../public/images/arrow_down_black.svg');
	width: 15px;
	height: 15px;
	display: block;
	background-position: center center;
	background-repeat: no-repeat;
}

.p-timepicker {
	justify-content: flex-end;
}

.p-datepicker .p-timepicker span {
	font-size: 13px;
}

.p-datepicker .p-timepicker button {
	width: 20px;
	height: 10px;
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
	z-index: 130000 !important;
}

.dialog__resetClient .p-dialog-header .p-dialog-header-icons {
	display: none;
}

.modal__back {
	display: none;
	cursor: pointer;
}

.create__job--remove-job {
	display: flex;
}

.hidden_x .p-dialog-header .p-dialog-header-icons {
	display: none !important;
}
