.notification__container {
	position: absolute;
	right: 150px;
	color: black;
	display: flex;
	flex-direction: column;
	top: 65px;
	padding-top: 5px;
	background: $color-white;
	box-shadow: 3px 4px 14px rgb(0 0 0 / 23%);
	border-radius: 0 0 10px 10px;
	max-height: 600px;
	min-width: 295px;
	border-radius: 0 0 10px 10px;
}

.notification__block {
	height: 100%;
	overflow: auto;
	border-radius: 10px;
}

.notification__block::-webkit-scrollbar {
	display: none;
}

.notification__title {
	padding: 5px 10px;
	h5 {
		font-weight: 400;
		font-size: 15px;
		line-height: 29px;
		color: $color-black;
	}
}

.notification__name {
	color: $color-name-notification;
	-webkit-line-clamp: 1;
}

.notification__name,
.notification__note {
	line-height: 18px;
	margin-bottom: 5px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.85);
}

.notification__note {
	width: 80%;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	margin-bottom: 10px;
	-webkit-line-clamp: 2;
}

.notification__alert {
	position: relative;
}

.notification__i {
	display: flex;
	justify-content: space-between;
}

.notification_item {
	border-bottom: 1px solid $color-border-input;
	padding: 10px 10px;
	cursor: pointer;
}

.notification_item.active {
	background-color: $color-input;
}

.notification_item:hover {
	background-color: $color-input;
	transition: 0.3s linear;
}

.notification__alert::before {
	content: '';
	width: 15px;
	height: 15px;
	background-color: $color-primary;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	right: 10px;
	font-size: 13px;
	margin-top: 2px;
	position: absolute;
	top: -30px;
	right: 0;
}

.notification__time {
	font-size: 10px;
}

.calendar__year input {
	background-color: $color-white;
}

.notify__time {
	font-size: 10px;
	width: 100%;
	display: block;
	text-align: right;
	color: $color-name-notification;
}
