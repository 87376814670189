.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	background: #ffffff;
	border-color: unset !important;
	color: $color-primary;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
	outline: none;
	outline-offset: 0;
	box-shadow: none;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
	background-color: $color-primary;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	border: solid #dee2e6;
	border-width: 0 0 2px 0;
	border-color: transparent transparent #dee2e6 transparent;
	background: #ffffff;
	color: #000;
	padding: 1.25rem;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	transition: box-shadow 0.2s;
	margin: 0 0 -2px 0;

	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
	background: #ffffff;
	border-color: none !important;
	color: inherit;
}
