.JobPerformance__block {
	border: 1px solid $color-border-input;
	border-radius: 10px;
	height: 500px;
	box-shadow: 0px 5px 4px rgb(0 0 0 / 25%);
	padding: 20px;
}

.chart__bar {
	padding-top: 50px;
}

.JobPerformance__container {
	padding-top: 30px;
}

.JobPerformance__avatar {
	border: 1px solid $color-border-input;
	border-radius: 50%;
	width: max-content;
	padding: 15px;
	display: flex;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.information__staff {
	padding-top: 50px;
}

.JobPerformance_text {
	padding: 6px;
}

.jobperformance_title {
	font-size: 13px;
	font-weight: bold;
}

::placeholder {
	display: block !important;
	font-size: 13px;
}
.chart__bonus {
	padding-top: 10px;
}
