.input_number {
	width: 100%;
	padding: 12.5px;
	border-radius: 5px;
	border: 1px solid #ced4da;
}

.input_number::placeholder {
	text-overflow: inherit;
	white-space: pre;
	overflow-wrap: normal;
	line-height: initial !important;
	-webkit-user-modify: read-only !important;
	overflow: hidden;
	font-size: 13px;
	font-family: 'Be VietNam';
}

.input_number::-webkit-input-placeholder {
	color: #6c757d;
}

input:focus-visible {
	outline: none;
}

.input_number {
	color: #495057;
	font-weight: 400;
	font-size: 13px;
}

.input_number:disabled {
	background-color: #f7f7f7 !important;
	border: 1px solid #e2e5e9;
	color: #a2a2a2;
}
