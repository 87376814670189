.nav__container .MuiPaper-root {
	background-color: $color-white !important;
	box-shadow: 3px 0px 4px rgb(0 0 0 / 25%);
}

.nav__container .MuiPaper-root::-webkit-scrollbar {
	display: none !important;
}

.nav__name {
	color: $color-white !important;
	position: relative;

	span {
		color: $color-black;
	}
}

.nav__name.disabled {
	opacity: 0;
	width: 0px;
}

.nav__name.enabled {
	opacity: 1;
	transition: 0.7s linear;
	width: 100%;
}

.nav__name.icon_arrow-up::after {
	content: '';
	background-image: url('../../../../public/images/arrow_up_black.svg');
	width: 15px;
	height: 15px;
	display: block;
	background-repeat: no-repeat;
	position: absolute;
	right: 10px;
	top: 25%;
}

.nav__name.icon_arrow-down::after {
	content: '';
	background-image: url('../../../../public/images/arrow_down_black.svg');
	width: 15px;
	height: 15px;
	display: block;
	background-repeat: no-repeat;
	position: absolute;
	right: 10px;
	top: 25%;
}

.nav__btnMenu {
	justify-content: flex-start !important;
}

.nav__icon {
	margin: 5px 10px;
	width: 24px;
}

.nav__button {
	height: 50px !important;
	padding: 0 !important;
	margin: 8px 12px !important;
	border-radius: 12px !important;
}

.show__password {
	width: 20px;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper,
.css-juqc2r-MuiPaper-root-MuiAppBar-root {
	box-shadow: none !important;
	border-bottom: 1px solid $color-border-input;
}

.MuiDrawer-paper::after {
	content: '';
}

.nav_btnChild.active {
	background-color: $color-nav-child-action !important;

	&::after {
		content: '';
		width: 2px;
		height: 100%;
		background-color: $color-primary;
		display: block;
		position: absolute;
		right: 0;
	}
}

.nav__container--nav-child {
	display: none;

	p {
		padding: 10px 0;
		color: $color-black;
	}

	.MuiButtonBase-root {
		padding-left: 50px !important;
	}
}

.nav__container--nav-child.active {
	display: block;
}

.nav__btn-active {
	.MuiTouchRipple-root {
		height: 35px !important;
		z-index: -99 !important;
		transform: translateY(-15%) !important;
		top: 25% !important;
	}
}

.nav__icon-active {
	position: relative;

	&::after {
		content: ' ';
		width: 100%;
		height: 100%;
		background: $active-nav !important;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -99;
	}
}

.font-bold {
	font-weight: bold !important;
	font-size: 13px;
}

.p-float-label,
.p-float-label a {
	font-size: 14px;
}

.p-inputtext {
	width: 100%;
	font-size: 13px;
	padding: 12px 35px 12px 12px;
	// background-color: $color-input;
}

.creat__job {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.create__job--title {
	background-color: $color-primary;
	padding: 15px;
	z-index: 1;
	align-items: center;

	h2 {
		color: $color-white;
	}

	p {
		color: $color-white;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		margin-top: 5px;
	}

	.id_job {
		color: $color-white;
		font-style: normal;
		font-weight: 500;
		font-size: 15px !important;
		line-height: 20px;
	}
}

.p-datepicker {
	z-index: 99999 !important;
	min-width: 250px !important;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
	color: $color-primary;
}

.creat__job--calendar {
	display: flex;
}

.creat__job--calendar {
	border: 1px solid $color-border-input;
	border-radius: 5px;
	padding: 0 15px !important;
}

.calendar__image {
	width: 20px;
	position: absolute;
	right: 10px;
	top: 15%;

	&--information {
		top: 30% !important;
	}
}

.calendar #range .p-inputtext {
	background-color: $color-white !important;
}

.p-dropdown-panel {
	z-index: 99999 !important;
	max-width: 243px;
}

.create__user--calendar,
.create__job--calendar {
	position: relative;
}

#calendar {
	width: 100%;
}

.create__job_type,
.create__role_type {
	width: 100%;
}

.create__job_area {
	// max-height: 150px;
	overflow: scroll;
}

.cancel--btn,
.confirm--btn {
	width: 100%;

	span {
		color: $color-primary;
	}
}

.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
	background: rgba(99, 102, 241, 0.04);
	color: #6366f1;
	border: 1px solid $color-primary;
}

.confirm--btn {
	background-color: $color-primary !important;

	&:hover {
		background-color: $hover-button-primary !important;
	}

	span {
		color: $color-white !important;
	}
}

.warning {
	color: $color-danger !important;
	padding-right: 5px;
	font-size: 16px;
}

.p-autocomplete-panel {
	z-index: 99999 !important;
}

.modal__creat--job {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	flex-wrap: nowrap;
	padding: 20px 20px 0 20px;
	font-weight: 600;

	.p-dropdown,
	.p-filled,
	.p-inputtext {
		background-color: $color-border-input;
	}

	.p-placeholder,
	.p-inputtextarea-resizable::placeholder {
		font-weight: 400;
	}

	.p-inputtextarea-resizable {
		background-color: #fff6e8;
		font-weight: 600;
	}

	.p-calendar-disabled {
		input {
			color: rgb(0, 0, 0);
		}
	}
	.p-disabled,
	.p-component:disabled {
		opacity: 1;
		color: black;
	}
}

.modal__creat--job::-webkit-scrollbar {
	display: none;
}

.btn_modal {
	align-items: flex-end;
	margin-bottom: 0px;
}

.p-dropdown:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled).p-focus,
.p-inputtext:enabled:hover {
	border: 1px solid $color-border-input;
	outline: none;
	outline-offset: none;
	box-shadow: none;
}

.p-inputtext:enabled:focus {
	outline: none;
	box-shadow: none;
}

.modal__creat--job .field div > span:first-child {
	font-size: 13px;
	color: $color-primary;
	display: flex;
}

.modal__creat--job .field div > span.title_child {
	font-size: 13px;
	color: $color-black;
	display: flex;
}

.create__job--title {
	color: $color-black;
	font-weight: bold;
	height: 64px;
}

#nameCustomer,
#id_editor {
	width: 100%;
}

#id_editor input,
#nameCustomer input {
	padding-right: 40px;
	border-radius: 5px;
}

.icon__search {
	position: relative;
}

.icon__search::before {
	content: '';
	background-image: url('../../../../public/images/search_user.svg');
	width: 30px;
	height: 30px;
	display: block;
	position: absolute;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 25px;
	right: 5px;
	top: 5px;
}

#id_editor button,
#nameCustomer button {
	background-color: unset !important;
	position: absolute;
	right: 0px;
	border: white;
	border-radius: 5px;
}

.p-dropdown .p-dropdown-label.p-placeholder {
	color: #6c757d;
	font-size: 13px;
}

.p-dropdown .p-dropdown-label {
	font-size: 13px;
}

.create_new_customer {
	margin-bottom: 10px;
	color: $color-primary;
	font-size: 14px;
	font-weight: bold;
	font-weight: 400;
	line-height: 22px;
	cursor: pointer;

	&--btn {
		display: flex;
		align-items: center;
		padding: 6px 10px;
		border: 0.4px solid #8f8d8d63;
		border-radius: 8px;
		box-shadow: 0 0 #0000, 0 0 #0000, 0 20px 25px -5px RGB(0 0 0 / 0.1), 0 8px 10px -6px RGB(0 0 0 / 0.1);
		transition: padding 0.3s ease;
	}

	&--btn:hover {
		padding: 6px 12px;
	}
}

.pi-chevron-left:before {
	content: '';
	background-image: url('../../../../public/images/arrow_left.svg');
	display: block;
	width: 15px;
	height: 15px;
	background-size: 8px;
	background-repeat: no-repeat;
	position: absolute;
	top: 10px;
	left: 10px;
}

.pi-chevron-right:before {
	content: '';
	background-image: url('../../../../public/images/arrow_right.svg');
	display: block;
	width: 15px;
	height: 15px;
	background-size: 8px;
	background-repeat: no-repeat;
	position: absolute;
	top: 10px;
	left: 10px;
}

#city,
#country,
#date {
	width: 100%;
}

.btn_information_jobs {
	font-size: 12px;
	color: $color-primary;
	font-weight: bold;
	padding-left: 20px;
	cursor: pointer;
}

.id_jobs {
	font-size: 12px;
	color: $color-black;
	font-weight: bold;
	padding-left: 20px;
}

.css-juqc2r-MuiPaper-root-MuiAppBar-root {
	background-color: $color-white !important;
}

.copy__icon,
.readonly-class {
	cursor: pointer;
}

.copy__icon {
	width: 18px;
	margin-right: 5px;
}

.p-toast-bottom-left {
	z-index: 100000000000000000000 !important;
}

.p-datepicker .p-datepicker-header {
	.p-datepicker-prev:focus,
	.p-datepicker-next:focus {
		outline: none !important;
		outline-offset: 0 !important;
		box-shadow: none !important;
	}
}

.p-datepicker table td > span.p-highlight {
	color: $color-primary;
}

.MuiList-root {
	height: 100%;
}

.p-slidemenu .p-menuitem-link:focus {
	outline: none;
	outline-offset: 0;
	box-shadow: none;
}

.p-dropdown-item > div {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: nowrap;
	max-width: 100%;
}

.p-menu .p-menuitem-link:focus {
	outline: none;
	outline-offset: 0;
	box-shadow: none;
}

.p-submenu-header {
	font-weight: 600 !important;
	font-size: 13px;
	padding-bottom: 0px !important;
}

.p-menu .p-menuitem-link {
	font-weight: 600 !important;
	font-size: 13px;
}

.dropdown__payment .p-inputtext {
	padding: 10px 35px 12px 12px !important;
}

.highlight__note {
	background: #fff6e8;
	font-weight: 600;
}

.reject__note {
	color: #000;
	font-weight: 600;
}
