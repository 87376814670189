.p-sidebar-mask {
	z-index: 99999 !important;
	transition: background-color 2s ease !important;
}

.create__job {
	width: 550px !important;
	&--remove-job {
		button:hover {
			background: #40a9ff !important;
		}
	}
}

.p-sidebar-header {
	display: none !important;
}
.icon__modal {
	height: 14px;
	width: 14px;
}

.image__trash {
	width: 15px;
}
