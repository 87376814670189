.css-1dxveab-MuiDrawer-docked .MuiDrawer-paper {
	z-index: 999999 !important;
}
.MuiButtonBase-root {
	padding-left: 0 !important;
}

@media (max-width: 1345px) {
	.login__container .login__bg .login__bg--title {
		left: 215px;
		bottom: 68%;
		p {
			font-size: 25px;
		}
		.login__note {
			font-size: 14px !important;
			width: 75%;
		}
	}
	.login__container .login__bg::before {
		left: 40px;
		top: 43%;
		background-size: 75%;
	}
	.login__bg {
		img {
			height: 80% !important;
		}
	}
}
@media (max-width: 1235px) {
	.title__selection {
		display: none !important;
	}

	.filter__calendar,
	.filter__search {
		width: 200px !important;
	}
}

@media (max-width: 1200px) {
	.table__total--workflow--management {
		.table__header-col span {
			color: $color-black;
		}
	}
	.edit_bank {
		width: 200px;
	}
}

@media (max-width: 1113px) {
	.page {
		padding-left: 82px;
		padding-right: 20px;
		height: 100%;
		background-color: #fff !important;
	}

	.chat {
		width: 500px;
	}

	.matching-results {
		text-align: center;
		display: block;
		padding: 20px;
		padding-bottom: 20px;
		background: white;
	}
	.table__limit {
		> span {
			padding-bottom: 0px;
			padding-top: 3px;
		}
	}

	.modal__back {
		display: block;
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
	.create__job--remove-job {
		display: none !important;
	}

	.btn_modal {
		.field:first-child {
			padding-right: 0px !important;
		}
	}

	.total__block {
		width: 49%;
		margin-bottom: 10px;
		text-align: inherit;
		height: 120px;
	}

	.total__block:last-child {
		width: 99%;
	}

	.p-datatable .p-datatable-tbody > tr > td {
		max-width: 100% !important;
		padding: 4.5px 12px;
	}

	.table__container {
		background-color: transparent;
		margin-top: 0px;
		margin-bottom: 0px;
		padding: 0px;
	}

	#outlined-size-small-label {
		font-size: 13px !important;
	}
	.hide_filter {
		display: none !important;
	}

	.table__header-name {
		display: none !important;
		color: $color-black !important;
	}
	.table__sort {
		display: none;
	}
	.table__body-name.btn_success,
	.table__body-name.btn_stop,
	.table__body-name.btn_pending {
		margin: 0px;
	}

	.p-column-title,
	.table__body-name {
		font-size: 12px;
	}
	.p-datatable-thead {
		display: none;
	}
	.table__limit > span {
		height: 40px;
	}
	.MuiSelect-select {
		padding: 0;
	}
	.paginate {
		padding: 15px 0;
	}
	.btn_filter {
		display: block;
		margin-left: 5px;
	}
	.login__container {
		width: 100%;
		height: 100vh;
	}
	.login__bg {
		display: none !important;
	}

	.login__form {
		background-color: $color-white;
		padding: 20px;
		border-radius: 20px;
		position: relative;
		// height: 70%;
	}
	.login__container .login__route {
		font-size: 30px;
		margin-bottom: 0px;
	}
	.login__container .login__form {
		max-width: 650px;
	}

	.login__container .login__form .login__title {
		flex-direction: column;
		p:first-child {
			display: none;
		}
		p:nth-child(2) {
			margin-bottom: 5px;
		}
	}

	.login__title p:last-child {
		position: relative;
	}

	.login__title p:last-child::after {
		content: ' ';
		background-image: url('../../../public/images/logo/logo_blue.svg');
		background-repeat: no-repeat;
		background-position: center;
		width: 150px;
		height: 150px;
		display: block;
		position: absolute;
		right: -40px;
		top: -50px;
		background-size: 100px;
	}

	.login__note {
		display: block;
	}

	.forgotpassword__bg {
		display: none;
	}
	.login__container:before {
		content: '';
		background-color: #fff;
		width: 100vw;
		height: 100vh;
		display: block;
		position: absolute;
		background-image: none;
	}

	.form__message {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 20px;
		margin: 0 20px;
		width: auto;
	}
	.forgot__form {
		margin-top: 40px;
	}

	.height-60 {
		height: 60%;
	}

	.header_total_controller {
		margin-left: 0px;
	}

	.p-datatable .p-datatable-tbody > tr {
		position: relative !important;
	}

	.select__perpage {
		display: none;
	}

	.select__perpage__text {
		display: none;
	}

	//tittle__index
	.tittle__index {
		display: none;
	}
	.table-pc {
		display: none !important;
	}

	.data__item {
		background-color: #fff;
		height: 100px;
		border: 2px solid $color-border-input;
		padding: 5px 5px 5px 0;
		border-radius: 5px;
		margin: 5px 2px;
	}

	.table-mobile {
		padding: 0px 0px 0px 65px;
		::before {
			content: '' !important;
			background-color: transparent !important;
		}
	}

	.data__status {
		margin: auto 0 !important;
		width: 25px !important;
		padding: 5px !important;
		border-radius: 0px 5px 5px 0px !important;
		height: 80% !important;
	}

	.data__status_editor {
		margin: 0 0 0 5px !important;
		width: 10px !important;
		padding: 5px !important;
		border-radius: 0px 5px 5px 0px !important;
		height: 100% !important;
	}

	.hidden__text {
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		white-space: nowrap;
		max-width: 300px;
	}

	.data_sales {
		padding-left: 15px;
		font-size: 13px;
		position: relative;
		span {
			color: $color-primary;
		}
		&.btn_primary {
			background-color: $color-infor;
			padding: 5px 10px;
			border-radius: 5px;
			margin-left: 10px;
			max-width: 150px;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: nowrap;
			width: 100%;
			border: 4px solid white;
		}
		&.btn_stop {
			background-color: #ffe8ec;
			padding: 5px 10px;
			border-radius: 5px;
			margin-left: 10px;
			max-width: 150px;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: nowrap;
			width: 100%;
			border: 4px solid white;
		}
		&.btn_success {
			background-color: #ecfbe6;
			padding: 5px 10px;
			border-radius: 5px;
			margin-left: 10px;
			max-width: 150px;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: nowrap;
			width: 100%;
			border: 4px solid white;
		}
		&.btn_pending {
			background-color: #fff6e8;
			padding: 5px 10px;
			border-radius: 5px;
			margin-left: 10px;
			max-width: 150px;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: nowrap;
			width: 100%;
			border: 4px solid white;
			display: flex;
		}

		&.editor__btn_primary::after {
			background-color: $color-infor;
			content: '';
			position: absolute;
			right: 0px;
			top: 0;
			bottom: 0px;
			width: 15px;
			border-left: 5px solid white;
			display: block;
		}

		&.editor__btn_stop::after {
			background-color: #ffe8ec;
			content: '';
			position: absolute;
			right: 0px;
			top: 0;
			bottom: 0px;
			width: 15px;
			border-left: 5px solid white;
			display: block;
		}

		&.editor__btn_success::after {
			background-color: #ecfbe6;
			content: '';
			position: absolute;
			right: 0px;
			top: 0;
			bottom: 0px;
			width: 15px;
			border-left: 5px solid white;
			display: block;
		}

		&.editor__btn_pending::after {
			background-color: #fff6e8;
			content: '';
			position: absolute;
			right: 0px;
			top: 0;
			bottom: 0px;
			width: 15px;
			border-left: 5px solid white;
			display: block;
		}
	}
	.data_date_start {
		font-size: 13px;
		padding-left: 15px;
		display: flex;
		align-items: center;
		&::before {
			content: '';
			background-image: url('../../../public/images/mobile/calendar_mobile.svg');
			background-position: center center;
			background-repeat: no-repeat;
			width: 20px;
			height: 20px;
			display: block;
		}
	}
	.data_date_end {
		font-size: 13px;
		padding-left: 15px;
		display: flex;
		align-items: center;
		&::before {
			content: '';
			background-image: url('../../../public/images/mobile/calendar_mobile_deadline.svg');
			background-position: center center;
			background-repeat: no-repeat;
			width: 20px;
			height: 20px;
			display: block;
		}
	}
	.data_cost {
		position: absolute !important;
		right: 15px !important;
		bottom: 65% !important;
		color: #223354;
		font-size: 16px;
		font-weight: 600;
	}

	.data_bonus {
		position: absolute !important;
		right: 15px !important;
		bottom: 45% !important;
		color: $color-primary;
		font-size: 16px;
		font-weight: 600;
	}

	.data_cost-perform {
		color: $color-avatar;
		font-size: 13px;
		position: absolute !important;
		left: 15px !important;
		top: 74% !important;
		font-weight: 600;
	}

	.data_bonus-perform {
		font-size: 13px;
		color: $color-primary;
		position: absolute !important;
		left: 15px !important;
		top: 50% !important;
		font-weight: 600;
	}

	.data_kpi-perform {
		font-size: 16px;
		color: $color-primary;
		position: absolute !important;
		top: 10% !important;
		font-weight: 600;
		right: 15px !important;
	}

	.data_chat {
		position: absolute !important;
		right: 15px !important;
		bottom: 5% !important;
		img {
			width: 30px;
		}
		color: $color-danger;
		font-size: 13px;
		div {
			position: absolute;
			bottom: 0px;
			right: -5px;
		}
	}
	.data_role {
		font-size: 13px;
		color: $color-primary;
		font-weight: 600;
		padding-left: 15px;
	}
	.staff__day-start {
		position: absolute;
		right: 15px;
		top: 65%;
	}
	.data_quality_img {
		padding-left: 15px;
		font-size: 13px;
		padding-top: 15px;
		span {
			color: $color-avatar;
		}
	}

	.data_incomplete-perform {
		position: absolute;
		top: 35px;
		right: 15px;
		font-size: 13px;
		display: flex;
		align-items: center;
		&::after {
			content: '';
			background-image: url('../../../public/images/mobile/uncomplete.png');
			background-position: center center;
			background-repeat: no-repeat;
			width: 15px;
			height: 15px;
			background-size: 15px;
			display: block;
			margin-left: 5px;
		}
	}

	.data_pending-perform {
		position: absolute;
		top: 55px;
		right: 15px;
		font-size: 13px;
		display: flex;
		align-items: center;
		color: $color-warning;
		&::after {
			content: '';
			background-image: url('../../../public/images/mobile/pending.png');
			background-position: center center;
			background-repeat: no-repeat;
			width: 15px;
			height: 15px;
			background-size: 15px;
			display: block;
			margin-left: 5px;
		}
	}

	.data_complete-perform {
		position: absolute;
		top: 75px;
		right: 15px;
		font-size: 13px;
		display: flex;
		align-items: center;
		color: $color-primary;
		&::after {
			content: '';
			background-image: url('../../../public/images/mobile/complete.png');
			background-position: center center;
			background-repeat: no-repeat;
			width: 15px;
			height: 15px;
			background-size: 15px;
			display: block;
			margin-left: 5px;
		}
	}

	.p-button-view {
		padding: 7px 25px;
		margin-bottom: 10px;
		margin-left: 20px;
	}

	.data__performance {
		height: 70px;
		border-bottom: 1px solid $color-border-input;
		padding: 0px 5px 0 0px;
		align-items: center;
		font-size: 30px;
		span {
			margin-left: 10px;
		}
		&.job_incomplete::before {
			content: '';
			background-color: #3ec180;
			width: 50px;
			height: 100%;
			display: block;
			border-radius: 0px 5px 0px 0px;
			background-image: url('../../../public/images/mobile/uncomplete.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 30px;
		}
		&.job_incomplete {
			span {
				color: #3ec180;
			}
		}
		&.job_pending::before {
			content: '';
			background-color: #ff6600;
			width: 50px;
			height: 100%;
			display: block;
			border-radius: 0px 5px 0px 0px;
			background-image: url('../../../public/images/mobile/pending.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 30px;
		}
		&.job_pending {
			span {
				color: #ff6600;
			}
		}
		&.job_complete::before {
			content: '';
			width: 50px;
			height: 100%;
			display: block;
			background-color: #3564db;
			border-radius: 0px 5px 0px 0px;
			background-image: url('../../../public/images/mobile/complete.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 30px;
		}
		&.job_complete {
			span {
				color: #3564db;
			}
		}
		&.job_cost::before {
			content: '';
			background-color: #fff;
			width: 50px;
			height: 100%;
			display: block;
			border: 2px solid $color-infor;
			border-radius: 0px 5px 0px 0px;
			background-image: url('../../../public/images/mobile/cost.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 40px;
		}
		&.job_cost {
			span {
				color: $color-infor;
			}
		}
		&.job_bonus::before {
			content: '';
			background-color: #fff;
			width: 50px;
			height: 100%;
			display: block;
			border: 2px solid $color-avatar;
			border-radius: 0px 5px 0px 0px;
			background-image: url('../../../public/images/mobile/bonus.png');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 40px;
		}
		&.job_bonus {
			span {
				color: $color-avatar;
			}
		}
	}
	.modal__name--group {
		width: 100%;
	}
	.btn__messager,
	#people-list {
		display: none !important;
	}

	.paymment__container {
		margin-left: 0;
		div {
			background-size: 100%;
			border-radius: 10px;
		}

		> div:first-child {
			background-image: url('../../../public/images/performance/total_job.svg');
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			&:after {
				content: '';
				background-image: url('../../../public/images/performance/total_job_ico.svg');
				background-position: center center;
				background-repeat: no-repeat;
				width: 50px;
				height: 100%;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				position: absolute;
			}
		}

		> div:nth-child(2) {
			background-image: url('../../../public/images/performance/payment.svg');
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			&:after {
				content: '';
				background-image: url('../../../public/images/performance/payment_ico.svg');
				background-position: center center;
				background-repeat: no-repeat;
				width: 50px;
				height: 100%;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				position: absolute;
			}
		}

		> div:nth-child(3) {
			background-image: url('../../../public/images/performance/pending.svg');
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			&:after {
				content: '';
				background-image: url('../../../public/images/performance/pending_ico.svg');
				background-position: center center;
				background-repeat: no-repeat;
				width: 50px;
				height: 100%;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				position: absolute;
			}
		}

		> div:nth-child(4) {
			background-image: url('../../../public/images/performance/total_cost.svg');
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			&:after {
				content: '';
				background-image: url('../../../public/images/performance/total_cost_ico.svg');
				background-position: center center;
				background-repeat: no-repeat;
				width: 50px;
				height: 100%;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				position: absolute;
			}
		}

		> div:nth-child(5) {
			background-image: url('../../../public/images/performance/complete.svg');
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			&:after {
				content: '';
				background-image: url('../../../public/images/performance/complete_ico.svg');
				background-position: center center;
				background-repeat: no-repeat;
				width: 50px;
				height: 100%;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				position: absolute;
			}
		}
	}

	.header_total_controller {
		div {
			background-size: 100%;
			border-radius: 10px;
		}

		> div:first-child {
			background-image: url('../../../public/images/performance/total_job.svg');
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			&:after {
				content: '';
				background-image: url('../../../public/images/performance/total_job_ico.svg');
				background-position: center center;
				background-repeat: no-repeat;
				width: 50px;
				height: 100%;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				position: absolute;
			}
		}

		> div:nth-child(2) {
			background-image: url('../../../public/images/performance/payment.svg');
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			&:after {
				content: '';
				background-image: url('../../../public/images/performance/payment_ico.svg');
				background-position: center center;
				background-repeat: no-repeat;
				width: 50px;
				height: 100%;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				position: absolute;
			}
		}

		> div:nth-child(3) {
			background-image: url('../../../public/images/performance/pending.svg');
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			&:after {
				content: '';
				background-image: url('../../../public/images/performance/pending_ico.svg');
				background-position: center center;
				background-repeat: no-repeat;
				width: 50px;
				height: 100%;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				position: absolute;
			}
		}

		> div:nth-child(4) {
			background-image: url('../../../public/images/performance/total_cost.svg');
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			&:after {
				content: '';
				background-image: url('../../../public/images/performance/total_cost_ico.svg');
				background-position: center center;
				background-repeat: no-repeat;
				width: 50px;
				height: 100%;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				position: absolute;
			}
		}

		> div:nth-child(5) {
			background-image: url('../../../public/images/performance/complete.svg');
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			&:after {
				content: '';
				background-image: url('../../../public/images/performance/complete_ico.svg');
				background-position: center center;
				background-repeat: no-repeat;
				width: 50px;
				height: 100%;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				display: block;
				position: absolute;
			}
		}
	}
}
@media (min-width: 1113px) {
	.table-mobile {
		display: none !important;
	}
}

@media (max-width: 865px) {
	.login__route {
		font-size: 25px !important;
	}
	.login__container .login__form .login__title p {
		font-size: 25px;
	}
	// .login__container .login__form{
	// 	width: 75%;
	// }
}

@media (min-width: 768px) {
	.isblock_personal_button {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.image__calendar {
		display: none;
	}
	.filter__mobile {
		display: block !important;
	}
	.messsager__container {
		bottom: 0;
		width: 100%;
		right: 0;
		left: 0;
	}
	.chat-container {
		position: absolute;
		right: 0px;
		//left: 0;
		bottom: 0px;
		box-shadow: 0px -2px 18px rgba(50, 50, 50, 0.35);
		border-radius: 20px 20px 0 0;
	}
	.chat {
		// width: 100%;
		border-radius: 15px 15px 0 0;
		.chat-header {
			border-radius: 20px 20px 0 0;
		}
	}
	.chat__close:before {
		content: '';
		background-color: transparent;
	}
	.chat__close:after {
		content: 'x';
		background-color: #fff;
		font-size: 25px;
		color: black;
		top: 6px;
		right: 15px;
	}

	.personal__information {
		display: none;
	}

	.header__none {
		position: absolute;
		right: 10px;
		width: 88%;
	}
	.header__right--profile,
	.header__right--information {
		width: max-content;
	}
	.notification-message__container,
	.notification__container {
		right: 3px;
	}

	.header__left {
		display: none;
	}
	.ava_right {
		margin-top: 255px !important;
		display: none;
	}
	.information__roles p,
	.information__name {
		display: none;
	}

	.avatar__block {
		padding-top: 10px;
	}

	.ava_res {
		display: flex;
	}

	.login__container form .form__email {
		margin-bottom: 30px;
	}
	.form__forgot-pwd {
		min-height: 475px;
	}
	.table__header-col span {
		font-size: 13px;
	}
	#people-list.active {
		width: 60px;
	}
	#people-list {
		width: 60px;
	}
	.notification-message__search {
		display: none;
	}
	.message__title {
		height: 71px;
	}
	.chat__createGroup:after {
		content: '+';
		position: absolute;
		left: 10px;
		width: 40px;
		height: 40px;
		background: $color-primary;
		font-size: 25px;
		border-radius: 5px;
		color: #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: -3px 4px 4px rgb(0 0 0 / 17%);
		cursor: pointer;
		top: 12px;
	}
	.chat .chat-history .message-data-time::after,
	.chat .chat-history .message-data-time::before {
		width: 30%;
	}
}

@media (max-width: 900px) {
	.hidden__header {
		display: none;
	}
	.msg__title {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.card__bank {
		height: 400px !important;
	}
	.card__name--bank {
		top: 40%;
	}
	.infor__content {
		display: none;
	}
	.isblock_personal_button {
		display: flex;
	}
	.ishidden_personal_button {
		display: none !important;
	}
	.filter__reset,
	.filter__dropdown,
	.filter__calendar {
		display: none;
	}
}

@media (min-width: 100px) {
	.css-hyum1k-MuiToolbar-root {
		min-height: 64px !important;
	}
}

@media (max-width: 739px) {
	.message_time {
		display: none;
	}
	.loader {
		left: 46%;
	}
	.personal_block {
		margin-left: 7px !important;
	}
	.page {
		padding-left: 20px;
	}
	.table-mobile {
		padding: 0px;
	}

	.css-148xt15 .MuiDrawer-paper,
	.css-1gn5dt1-MuiDrawer-docked .MuiDrawer-paper {
		width: calc(0px + 1px) !important;
	}

	.job__header {
		font-size: 12px;
	}

	.card__number--bank {
		margin-top: 10px;
	}
	.chart__color {
		height: 100px;
	}

	.header__container {
		height: 120px;
	}

	.table__add {
		position: absolute;
		right: 0;
		top: -12px;
		padding: 0px 30px;
	}

	.table__icon--name {
		display: none;
	}

	.table__icon--add {
		padding: 0;
	}

	.page__title {
		font-size: 16px;
		margin-top: 6px;
		margin-left: -80px;
	}

	.table__header-col span {
		font-size: 12px;
	}
	.table__body-name.btn_pending,
	.table__body-name.btn_stop,
	.table__body-name.btn_success {
		height: 30px;
		width: 135px;
	}
	.table__perpage {
		span {
			font-size: 14px;
		}
	}

	.edit__group {
		padding-left: 69px;
	}

	.toast {
		padding: 5px !important;
		width: 300px !important;
	}
	.personal__container {
		padding: 0px;
	}
	.toast__msg {
		font-size: 13px !important;
	}
	#toast {
		right: 50% !important;
		transform: translateX(50%);
	}
}
@media (max-width: 600px) {
	.header__right {
		justify-content: flex-end;
	}

	.header__menu {
		top: 59px !important;
	}

	.total__block {
		width: 100%;
	}
}

@media (max-width: 520px) {
	.chat {
		width: 100%;
	}
	// .chat-container {
	// 	justify-content: end;
	// 	box-shadow: none;
	// 	height: 600px;
	// }
	// .chat .chat-history {
	// 	height: 425px;
	// }
	.job__header {
		font-size: 11px;
	}
	.card__bank {
		height: 250px !important;
	}
	.card__number--bank,
	.card__nameUser--bank,
	.card__name--bank {
		font-size: 13px !important;
	}
	.card__number--bank {
		margin-top: 5px;
	}
	.header__block {
		width: 83%;
	}
	.header__left span {
		display: none;
	}
	.login__form {
		margin: auto 20px !important;
		width: 100% !important;
	}

	.id__me {
		display: none;
	}
	.btn-cfirm,
	.btn-cancel {
		font-size: 13px;
		width: 100px !important;
	}
}

@media (max-width: 420px) {
	.card__bank {
		height: 240px !important;
	}
}

@media (max-height: 900px) {
	.chat-container {
		max-height: 500px;
	}
	.chat-history {
		max-height: 320px;
	}
}

@media (min-height: 1200px) {
	.chat-container {
		min-height: 1000px;
	}
	.chat-history {
		min-height: 820px;
	}
}
